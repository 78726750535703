import React, { createContext, useCallback, useContext, useMemo, useState, useRef, useEffect } from "react";
import PropTypes from 'prop-types';
import NotificationSystem from 'react-notification-system';
import { WS_BASE_URL } from 'utils/api';
import Swal from 'sweetalert2'
import { io } from "socket.io-client";

const socket = io(WS_BASE_URL, {
    path: '/ws',
    reconnectionDelayMax: 10000,
    transports: ['websocket']
});

export const AuthContext = createContext();
const SICOB_APP_LOCATION = 'SICOB_APP_LOCATION';
const SICOB_APP_TOKEN = 'SICOB_APP_TOKEN';
const SICOB_APP_USER = 'SICOB_APP_USER';
const SICOB_APP_AGENTE = 'SICOB_APP_AGENTE';
const SICOB_APP_TASKS = 'SICOB_APP_TASKS';
const SICOB_APP_MESSAGES = 'SICOB_APP_MESSAGES';
const SICOB_APP_NOTIFICATIONS = 'SICOB_APP_NOTIFICATIONS';
const SICOB_APP_TIPO_CAMBIO = 'SICOB_APP_TIPO_CAMBIO';

export function AuthContextProvider({ children }) {
    const [headerTitle, setHeaderTitle] = useState('');
    const position = useRef({ latitude: 0, longitude: 0 });
    const [token, setToken] = useState(window.localStorage.getItem(SICOB_APP_TOKEN));
    const [user, setUser] = useState(JSON.parse(window.localStorage.getItem(SICOB_APP_USER)));
    const [collapsedNavbar, setCollapsedNavbar] = useState(user ? user.navbar_colapsed: false ); 
    const [agente, setAgente] = useState(JSON.parse(window.localStorage.getItem(SICOB_APP_AGENTE)));
    const [tipoCambio, setTipoCambio] = useState(JSON.parse(window.localStorage.getItem(SICOB_APP_TIPO_CAMBIO)));
    const notificaciones = useRef(null);
    const [tasks, setTasks] = useState(JSON.parse(window.localStorage.getItem(SICOB_APP_TASKS))|| []);
    const [messages, setMessages] = useState(JSON.parse(window.localStorage.getItem(SICOB_APP_MESSAGES))|| []);
    const [notifications, setNotifications] = useState(JSON.parse(window.localStorage.getItem(SICOB_APP_NOTIFICATIONS))|| []);

    const login = useCallback(function( token, user, agente ) {
        window.localStorage.setItem(SICOB_APP_TOKEN,token);
        setToken(token);
        window.localStorage.setItem(SICOB_APP_USER,JSON.stringify(user));
        setUser(user);
        window.localStorage.setItem(SICOB_APP_AGENTE,JSON.stringify(agente));
        setAgente(agente);
    },[]);

    const logout = useCallback(function(){
        setToken(null);
        setUser(null);
        window.localStorage.removeItem(SICOB_APP_TOKEN);
        window.localStorage.removeItem(SICOB_APP_USER);
        window.localStorage.removeItem(SICOB_APP_TASKS);
        window.localStorage.removeItem(SICOB_APP_MESSAGES);
        window.localStorage.removeItem(SICOB_APP_NOTIFICATIONS);
        window.localStorage.removeItem(SICOB_APP_TIPO_CAMBIO);
        window.localStorage.removeItem(SICOB_APP_AGENTE);
    },[]);

    const establecerTipoCambio = useCallback(function(tipoCambio){
        window.localStorage.setItem(SICOB_APP_TIPO_CAMBIO,tipoCambio);
        setTipoCambio(tipoCambio);
    },[]);

    const establecerDatosUsuario = useCallback(function(tasks, messages, notifications){
        window.localStorage.setItem(SICOB_APP_TASKS,JSON.stringify(tasks));
        setTasks(tasks);
        window.localStorage.setItem(SICOB_APP_MESSAGES,JSON.stringify(messages));
        setMessages(messages);
        window.localStorage.setItem(SICOB_APP_NOTIFICATIONS,JSON.stringify(notifications));
        setNotifications(notifications);
    },[]);

    const openAlert = useCallback(function(notification){
        Swal.fire({
            // text: notification.message,
            title: notification.message,
            icon: notification.type,
            position: 'top-end',
            showConfirmButton: false,
            timer: 1500
        });
    },[]);

    const notify = useCallback(function(params){
        if(!notificaciones.current)
          return setTimeout(() => notify(params),1000);
        notificaciones.current.addNotification({
          level: params.type,
          position: 'br',
          ...params,
          children: params.link && (
            <div>
              <a href={'/'+params.link} className="btn btn-outline-warning btn-sm">Ver más...</a>
            </div>
          )
        });
    },[]);

    const wsSend = useCallback(function(data){
        if(!socket.connected)
            return setTimeout(wsSend.bind(this,data),1000);
        socket.emit(data.type,data);
    },[]);

    useEffect(()=>{
        socket.on("connect", () => {
            console.log('WebSocket Client Connected');
            if(token)
                socket.emit('LOGIN', { fullname: user.name, email: user.email, userId: user.id });
        });
        socket.on("disconnect", (reason) => { });
        socket.on("LOGIN", (event) => {
            const data = event;
            const type = data.type || 'success';
            const title = data.title || 'Alerta';
            notify({ type, message: data.message, title });
        });
        socket.on("NOTIFICATION", (event) => {
            const data = event;
            const type = !data.type || data.type == 'NOTIFICATION' ? 'success': data.type;
            const title = data.title || 'Alerta';
            notify({ type, message: data.message, title });
        });
    },[]);

    useEffect(()=>{
        if(token) {
            navigator.geolocation.watchPosition((ubicacion) => { 
                const coordenadas = ubicacion.coords;
                position.current = { latitude: coordenadas.latitude, longitude: coordenadas.longitude };
            }, ()=>{});
        }
    },[token]);

    const value = useMemo(
        ()=>({
            login,
            logout,
            token,
            user,
            agente,
            tipoCambio,
            establecerTipoCambio,
            openAlert,
            tasks, 
            messages, 
            notifications,
            notify,
            establecerDatosUsuario,
            collapsedNavbar, 
            setCollapsedNavbar,
            wsSend,
            headerTitle,
            setHeaderTitle,
            position
        }),
        [   
            login, 
            logout, 
            token, 
            user, 
            tipoCambio,
            establecerTipoCambio,
            agente,
            openAlert, 
            tasks, 
            messages, 
            notifications,
            notify,
            establecerDatosUsuario,
            collapsedNavbar, 
            setCollapsedNavbar,
            wsSend,
            headerTitle,
            setHeaderTitle,
            position
        ]
    );
    return (
    <AuthContext.Provider value={value}>
        {children}
        <NotificationSystem ref={notificaciones} />
    </AuthContext.Provider>);
}

AuthContextProvider.protoTypes = {
    children: PropTypes.object
}


export function useAuthContext() {
    return useContext(AuthContext);
}