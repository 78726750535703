import axios from 'axios';
import jwt from 'jwt-simple';
import { API_KEY } from 'config/apiKey';
import { URLS_DEV, URLS_PROD } from 'config/constants';

export const BASE_URL = process.env.NODE_ENV === 'production' ? URLS_PROD.API : URLS_DEV.API;
export const APP_URL = process.env.NODE_ENV === 'production' ? URLS_PROD.APP : URLS_DEV.APP;
export const WS_BASE_URL = process.env.NODE_ENV === 'production' ? URLS_PROD.WS : URLS_DEV.WS;
export const URL_FILES = BASE_URL.substring(0, BASE_URL.length - 4);

const request = axios.create({
  baseURL: BASE_URL
});

request.interceptors.request.use(
  request => {
    const token = window.localStorage.getItem('SICOB_APP_TOKEN')
    const user = JSON.parse(window.localStorage.getItem('SICOB_APP_USER'))
    request.headers['X-Api-Key'] = API_KEY;
    if (token) {
      try {
        jwt.decode(token, API_KEY);
      } catch (e) {
        window.localStorage.removeItem('SICOB_APP_TOKEN')
        window.localStorage.removeItem('SICOB_APP_USER')
        window.localStorage.removeItem('SICOB_APP_AGENTE')
        window.localStorage.removeItem('SICOB_APP_TIPO_CAMBIO')
        window.localStorage.removeItem('SICOB_APP_TASKS')
        window.localStorage.removeItem('SICOB_APP_MESSAGES')
        window.localStorage.removeItem('SICOB_APP_NOTIFICATIONS')
        window.location.href = '/login';
      }
      request.credentials = 'include';
      request.headers.Authorization = 'Bearer ' + token;
      request.headers.User = user.email;
    }
    return request;
  },
  error => {
    return Promise.reject(error)
  }
)

const notificar = (error, params ) => {
  const status = error.response ? error.response.status : 404;
  const method = error.config ? error.config.method : '';
  const data = error.config ? error.config.data : {};
  const url = error.config ? error.config.url : '';
  let message = error.response ? error.response.statusText: '';
  let title = 'Advertencia';
  let type = error.response && error.response.data ? error.response.data.type: 'error';
  if ( process.env.NODE_ENV !== 'production' ) console.log(status, message, error.response ? error.response: error);
  switch(status){
    case 500: 
      title = 'Oops...';
      message = '¡Algo salio mal!'; 
      break;
    case 404: 
      title = 'Oops...';
      message  = 'No es posible acceder a los servicios de la aplicación.'; 
      break;
    case 401: 
    case 422: 
      message  = error.response && error.response.data ? error.response.data.message: message; 
      break;
  }
  if ( process.env.NODE_ENV === 'production' ) {
    axios.post('https://hooks.slack.com/services/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',
      JSON.stringify({
        text: `Usuario: ${localStorage.email}
        Error: ${message}
        Url: ${url}
        Metodo: ${method}
        Status: ${status}
        Data: ${JSON.stringify(data)}
        `
      }));
  }

  params.notification({
    title,
    message,
    type: type ? type: 'error'
  });
};

const notificarSuccess = (result, notification ) => {
  let response = result.response ? result.response: result;
  let message = response.data ? response.data.message: '';
  let type = response.data && response.data.type ? response.data.type: 'success';
  // if ( process.env.NODE_ENV !== 'production' ) console.log( message);
  if(type && message){
    notification({
      title: 'Atención',
      message: message,
      type
    });
  }
};

export const getRequest = ( params ) => {
  params = { success: () => {}, onProgress: () => {}, fail: () => {}, finally: () => {}, notification: () => {}, ...params };
  if ( validateParameters(params) ) {
    request
      .get(params.url, params.cancelToken ? { cancelToken: params.cancelToken } : {})
      .then(response => params.success(response.data))
      .catch(error => {
        if(params.notification) notificar( error, params );
        params.fail(error.response ? error.response.statusText : '', error.response ? error.response.status : '' , error.response ? error.response.data : '' );
      }).finally(()=>{
        params.finally();
      });
  }
};

export const postRequest = (params) => {
  params = { success: () => {}, onProgress: () => {}, fail: () => {}, finally: () => {}, notification: () => {}, ...params };
  if ( validateParameters(params) ) {
    request
      .post(params.url, params.body, { responseType: 'json', cancelToken: params?.cancelToken })
      .then(response => {
        if(params.notification) notificarSuccess( response, params.notification );
        params.success(response.data, response.status);
      })
      .catch(error => {
        if(params.notification) notificar( error, params );
        params.fail(error.response ? error.response.statusText : '', error.response ? error.response.status : '' , error.response ? error.response.data : '' );
      }).finally(()=>{
        params.finally();
      });
  }
};

export const postUploadRequest = (params) => {
  params = { success: () => {}, onProgress: () => {}, fail: () => {}, finally: () => {}, notification: () => {}, ...params };
  if ( validateParameters(params) ) {
    request
      .post(params.url, params.form, { 
        responseType: 'json', 
        cancelToken: params?.cancelToken,
        headers: {
          'Content-Type': 'multipart/form-data'
      }, 
      onUploadProgress: params.onProgress()
    })
      .then(response => {
        if(params.notification) notificarSuccess( response, params.notification );
        params.success(response.data, response.status);
      })
      .catch(error => {
        if(params.notification) notificar( error, params );
        params.fail(error.response ? error.response.statusText : '', error.response ? error.response.status : '' , error.response ? error.response.data : '' );
      }).finally(()=>{
        params.finally();
      });
  }
};

export const putRequest = (params) => {
  params = { success: () => {}, onProgress: () => {}, fail: () => {}, finally: () => {}, notification: () => {}, ...params };
  if ( validateParameters(params) ) {
    request
      .put(params.url, params.body, { 
        responseType: 'json',
        cancelToken: params?.cancelToken,
      })
      .then(response => {
        if(params.notification) notificarSuccess( response, params.notification );
        params.success(response.data, response.status);
      })
      .catch(error => {
        if(params.notification) notificar( error, params );
        params.fail(error.response ? error.response.statusText : '', error.response ? error.response.status : '' , error.response ? error.response.data : '' );
      }).finally(()=>{
        params.finally();
      });
  }
};

export const putUploadRequest = (params) => {
  params = { success: () => {}, onProgress: () => {}, fail: () => {}, finally: () => {}, notification: () => {}, ...params };
  if ( validateParameters(params) ) {

    request
      .put(params.url, params.form, {
        cancelToken: params?.cancelToken,
        responseType: 'json',
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        onUploadProgress: params.onProgress()
      })
      .then(response => {
        if(params.notification) notificarSuccess( response, params.notification );
        params.success(response.data, response.status);
      })
      .catch(error => {
        if(params.notification) notificar( error, params );
        params.fail(error.response ? error.response.statusText : '', error.response ? error.response.status : '' , error.response ? error.response.data : '' );
      }).finally(()=>{
        params.finally();
      });
  }
};

export const deleteRequest = (params) => {
  params = { success: () => {}, onProgress: () => {}, fail: () => {}, finally: () => {}, notification: () => {}, ...params };
  if ( validateParameters(params) ) {
    request
      .delete(params.url, params.cancelToken ? { cancelToken: params.cancelToken } : {})
      .then(response => {
        if(params.notification) notificarSuccess( response, params.notification );
        params.success(response.data, response.status);
      })
      .catch(error => {
        if(params.notification) notificar( error, params );
        params.fail(error.response ? error.response.statusText : '', error.response ? error.response.status : '' , error.response ? error.response.data : '' );
      }).finally(()=>{
        params.finally();
      });
  }
};

const validateParameters = (params) => {
  let successfully = true;

  if ( typeof(params.url) !== 'string' ) {
    successfully = false;
    throw 'url: undefined string';
  }

  if ( typeof(params.success) !== 'function' || typeof(params.fail) !== 'function' ) {
    successfully = false;
    throw 'undefined functions callback';
  }
  return successfully;
};

export const pathFilesApi = () => {
  return BASE_URL.substring(0, BASE_URL.length - 4);
};

export const urlPhoto = () => {
  return BASE_URL.substring(0, BASE_URL.length - 4);
};

export const imageExists = (imageUrl) => {
  var http = new XMLHttpRequest();

  http.open('HEAD', imageUrl, false);
  http.send();

  if ( http.status === 404 || http.status === 401 ) {
    return false;
  }
  return true;
};
